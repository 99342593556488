<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Name</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="name"
          v-model="name"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter name"
          :rules="[required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="values">Values</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="values"
          v-model="values"
          type="textarea"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter values"
          :rules="[isNotEmptyString]"
        ></v-textarea>

        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
          class="mt-2"
        >
          Press Enter twice to split bio
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
      </v-col>

      <v-col
        cols="12"
        md="9"
        class="scroll-bio"
      >
        <v-data-table
          hide-default-footer
          :mobile-breakpoint="0"
          :items="calculatedOptions"
          :headers="[{
            text: 'Bios',
            value: 'bios',
          }]"
          disable-pagination
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { required, isNotEmptyString } from '@core/utils/validation'

export default {
  props: {
    bio: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ bio }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }
    const values = ref(bio.value.join('\n\n'))
    const name = ref(bio.name)
    const calculatedOptions = computed(() => values.value
      .split('\n\n')
      .filter(el => el)
      .map(el => ({ bios: el })))

    const submit = async () => ({
      id: bio.id,
      name: name.value,
      value: values.value.trim().split('\n\n').filter(el => el),
    })

    return {
      form,
      name,
      values,

      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      calculatedOptions,
    }
  },
}
</script>
