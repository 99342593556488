var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedBios.length),expression:"selectedBios.length"}],staticClass:"mr-6",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.tryDeleteManyBios(_vm.selectedBios)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1),_c('TableSearch',{attrs:{"hide-filter":""},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}}),_c('v-spacer'),_c('AddNewBio',{on:{"submitted":_vm.tryFetchBios}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading bios ...","server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.bios,"show-expand":"","headers":_vm.bioHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.text",fn:function(ref){
      var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":"1"}}),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('v-row',{staticClass:"my-2"},_vm._l((item.value),function(v,index){return _c('v-col',{key:index,attrs:{"cols":"6","md":"4"}},[_c('span',{staticClass:"bios"},[_vm._v(" "+_vm._s(v)+" ")])])}),1)],1)]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openBioEditModal(item)},"delete":function($event){return _vm.tryDeleteBio(item.id)}}})]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedBios),callback:function ($$v) {_vm.selectedBios=$$v},expression:"selectedBios"}})],1),_c('BioEditModal',{ref:"editBioModal",on:{"update":_vm.tryUpdateBio}}),_c('ConfirmDialog',{ref:"deleteBioConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var agree = ref.agree;
      var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete bio")]),_c('v-card-text',[_vm._v(" Do you really want to delete bio ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }