<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="sheet"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :attrs="attrs"
          :elevation="12"
          fab
          v-on="on"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <AddBioForm
        v-if="sheet"
        :loading="loading"
        @submit="submit"
      />
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

import store from '@/store'

import AddBioForm from './AddForm.vue'
import { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

export default {
  components: { AddBioForm },
  data: () => ({
    mdiPlus,
    sheet: false,
    loading: false,
  }),
  methods: {
    async submit(bio) {
      this.loading = true
      try {
        await store.dispatch(`${DISCORD_MODULE_NAME}/addBio`, { bio })
        this.sheet = false
        this.loading = false
        this.$emit('submitted')
        this.$message.success('Bio added successfully')
      } catch (err) {
        this.sheet = false
        this.loading = false
        this.$message.error(err)
      }
    },
  },
}
</script>
