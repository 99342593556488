<template>
  <v-card>
    <v-card-title>Create Bio</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="text">Name</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="name"
              v-model="name"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter name"
              :disabled="loading"
              :rules="[required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="values">Values</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-textarea
              id="values"
              v-model="values"
              type="textarea"
              outlined
              dense
              hide-details="auto"
              placeholder="Enter values"
              :disabled="loading"
              :rules="[isNotEmptyString]"
            ></v-textarea>

            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
              class="mt-2"
            >
              Press Enter twice to split bio
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
          </v-col>

          <v-col
            cols="12"
            md="9"
            class="scroll-bio"
          >
            <v-data-table
              hide-default-footer
              :mobile-breakpoint="0"
              :items="calculatedOptions"
              :headers="[{
                text: 'Bios',
                value: 'bios',
              }]"
              disable-pagination
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-col
        cols="12"
        class="pl-0"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Create
        </v-btn>
        <v-btn
          type="reset"
          class="mx-2"
          :disabled="loading"
          outlined
          @click="reset"
        >
          Reset
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { required, isNotEmptyString } from '@core/utils/validation'

export default {
  props: {
    loading: Boolean,
  },
  setup(props, { emit }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }
    const values = ref('')
    const name = ref('')
    const calculatedOptions = computed(() => values.value
      .split('\n\n')
      .filter(el => el)
      .map(el => ({ bios: el })))

    const submit = async () => {
      if (validate()) {
        const data = {
          name: name.value,
          value: values.value.trim().split('\n\n').filter(el => el),
        }
        emit('submit', data)
      }
    }

    return {
      form,
      name,
      values,

      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      calculatedOptions,
    }
  },
}
</script>

<style>
.scroll-bio{
max-height: 300px;
overflow-y: auto;
}
</style>
